var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-page-new',{attrs:{"root":"Головна","title":"Відео конвертер","subtitle":"Статистика","icon":"mdi-video"},scopedSlots:_vm._u([{key:"header_action",fn:function(){return [_c('ws-text-field',{attrs:{"placeholder":"Пошук","icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"default",fn:function(){return [_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.items,"headers":_vm.headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.business_uuid",fn:function(ref){
var item = ref.item;
return [_c('h5',{style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.business_uuid))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.quality",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-center",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.quality))])]}},{key:"item.file_path",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(item.file_path))])]}},{key:"item.start_size",fn:function(ref){
var item = ref.item;
return [(item.start_size)?_c('h5',{staticClass:"text-center",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.start_size)+" MB")]):_vm._e()]}},{key:"item.result_size",fn:function(ref){
var item = ref.item;
return [(item.result_size)?_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(item.result_size)+" MB")]):_vm._e()]}},{key:"item.duration_absolute",fn:function(ref){
var item = ref.item;
return [(item.duration)?_c('h5',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-clock")]),_vm._v(_vm._s(item.duration))],1):_vm._e()]}},{key:"item.waiting_absolute",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.waiting)?_c('h5',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-clock")]),_vm._v(_vm._s(item.waiting))],1):_vm._e()])]}},{key:"item.rabbit_time",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.PARSE_TIME_STAMP(item.rabbit_time)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":_vm.wsACCENT},on:{"click":function($event){return _vm.openDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})]},proxy:true},{key:"dialog",fn:function(){return [_c('ws-dialog',{attrs:{"title":"Видалити запис ","display-confirm-delete":"","close-delete":"","show-delete":""},on:{"delete":_vm.deleteRecord},model:{value:(_vm.displayDeleteDialog),callback:function ($$v) {_vm.displayDeleteDialog=$$v},expression:"displayDeleteDialog"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }