<template>
  <dash-page-new
      root="Головна"
      title="Відео конвертер"
      subtitle="Статистика"
      icon="mdi-video"
  >

    <template #header_action>
      <ws-text-field v-model="search" placeholder="Пошук" icon="mdi-magnify" />
    </template>

    <template #default>
      <ws-data-table
          class="mt-5"
          :items="items"
          :headers="headers"
          :search="search"
      >
        <template #item.business_uuid="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.business_uuid }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 class="text-no-wrap" > {{ item.name }}</h5>
        </template>

        <template  #item.quality="{item}">
          <h5 class="text-center" :style="`color : ${wsACCENT}`" >{{ item.quality }}</h5>
        </template>

        <template #item.file_path="{item}">
          <h5 class="text-center" >{{ item.file_path }}</h5>
        </template>

        <template  #item.start_size="{item}">
          <h5 class="text-center" v-if="item.start_size" :style="`color : ${wsACCENT}`" >{{ item.start_size }} MB</h5>
        </template>

        <template #item.result_size="{item}">
          <h5 class="text-center" v-if="item.result_size"  >{{ item.result_size }} MB</h5>
        </template>

        <template #item.duration_absolute="{item}">
          <h5 v-if="item.duration"  class="text-no-wrap" ><v-icon class="mr-2" :color="wsACCENT">mdi-clock</v-icon>{{ item.duration }}</h5>
        </template>

        <template #item.waiting_absolute="{item}">
          <span>
            <h5 v-if="item.waiting" class="text-no-wrap" ><v-icon class="mr-2" :color="wsACCENT">mdi-clock</v-icon>{{ item.waiting }}</h5>
          </span>
        </template>

        <template #item.rabbit_time="{item}">
          <h5 class="text-no-wrap" > {{ PARSE_TIME_STAMP(item.rabbit_time) }}</h5>
        </template>


        <template #item.action="{item}">
          <v-btn icon @click="openDelete(item)" :color="wsACCENT"><v-icon>mdi-delete</v-icon></v-btn>
        </template>


      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          title="Видалити запис "
          v-model="displayDeleteDialog"
          @delete="deleteRecord"
          display-confirm-delete
          close-delete
          show-delete

      >

      </ws-dialog>
    </template>


  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AdminConverter",
  data() {
    return {
      items : [],
      search : '',
      displayDeleteDialog : false,
      selectedItem : {}
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Дата' , value : 'rabbit_time' },
        { text : 'Бізнес', value : 'business_uuid' },
        { text : 'Назва' , value : 'name' },
        { text : 'Якість' , value : 'quality' },
        { text : 'Оригінал' , value : 'file_path' },
        { text : 'Розмір' , value : 'result_size' },
        { text : 'Розмір до', value : 'start_size' },
        { text : 'Час', value : 'duration_absolute' },
        { text : 'Очікування', value : 'waiting_absolute' },
        { value : 'action' },

      ]
    },

  },
  methods : {
    ...mapActions('converter' , ['GET_CONVERTER_STATISTICS' , 'DELETE_STATISTICS_RECORD']),

    openDelete(item) {
      this.selectedItem = Object.assign({} , item)
      this.displayDeleteDialog = true
    },
    async deleteRecord() {
      let result = this.DELETE_STATISTICS_RECORD(this.selectedItem.uuid)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex( el=> el.uuid === this.selectedItem.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index, 1)
      this.displayDeleteDialog = false
    },


    //technical
    async initPage() {
      let result = await this.GET_CONVERTER_STATISTICS()
      if ( !result || result === true ) {
        return
      }

      result.forEach(el => {
        if ( el.end_time ) {

          el.duration = ''

          el.duration_absolute = el.end_time - el.start_time

          var diffMs = (new Date(el.end_time*1000) - new Date(el.start_time*1000)); // milliseconds between now & Christmas
          let diffDays = Math.floor(diffMs / 86400000); // days
          let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
          let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

          if ( diffDays ) {
            el.duration += diffDays + ' днів '
          }
          if ( diffHrs ) {
            el.duration += diffHrs + ' год '
          }
          if ( diffMins ) {
            el.duration += diffMins + ' хв '
          }

          el.waiting = ''
          el.waiting_absolute = el.start_time - el.rabbit_time

          diffMs = (new Date(el.start_time*1000) - new Date(el.rabbit_time*1000)); // milliseconds between now & Christmas
          diffDays = Math.floor(diffMs / 86400000); // days
          diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
          diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

          if ( diffDays ) {
            el.waiting += diffDays + ' днів '
          }
          if ( diffHrs ) {
            el.waiting += diffHrs + ' год '
          }
          if ( diffMins ) {
            el.waiting += diffMins + ' хв '
          }

        }

      })

      this.items = result

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>